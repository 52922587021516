import React from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { propTypes } from '../../util/types';

import { LayoutSingleColumn, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import css from './LandingPageClient.module.css';

import SectionHeroClient from './sections/SectionHeroClient/SectionHeroClient';
import SectionSearch from './sections/SectionSearch/SectionSearch';

import { isScrollingDisabled } from '../../ducks/ui.duck';

export const LandingPageClientComponent = props => {

  const { scrollingDisabled } = props;

  return (
    <Page scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn
        topbar={<TopbarContainer rootClassName={css.topbarRoot} isPartnersLandingPage={false} isLandingPage={true} />}
        footer={<FooterContainer />}
      >
        <SectionHeroClient />
        <SectionSearch />
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageClientComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  scrollingDisabled: bool.isRequired,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return {
    pageAssetsData,
    inProgress,
    error,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPageClient = compose(connect(mapStateToProps))(LandingPageClientComponent);

export default LandingPageClient;
