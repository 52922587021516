import React, { useState } from 'react';
import { FormattedMessage, useIntl } from '../../../../util/reactIntl';
import { H2 } from '../../../../components';
import { pushToPath } from '../../../../util/urlHelpers';

import shopHero from './img/background.jpg'
import searchIcon from './img/searchIcon.png'
import css from './SectionHeroClient.module.css';

const KEY_CODE_ENTER = 13;

const SectionHeroClient = props => {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = event => {
    setInputValue(event.target.value);
  };

  const handleSearch = () => {
    if(inputValue !== '') {
      pushToPath(`/s?bounds=52.61334649%2C13.49248918%2C52.39936027%2C13.21996925&keywords=${inputValue}`);
    } else {
      pushToPath(`/s`);
    }

  };

  const handleKeyDown = event => {
    if (event.keyCode === KEY_CODE_ENTER) {
      event.preventDefault();
      handleSearch();
    }
  };

  return (
    <div className={css.root}>
      <div className={css.backgroundImageWrapper}>
        <img
          className={css.backgroundImage}
          src={shopHero}
        />
        <div className={css.overlay} />

      </div>
      <div className={css.sectionContent}>
        <div className={css.infoContainer}>
          <H2 className={css.title}>
            <FormattedMessage
              id="SectionHeroClient.title"
              values={{ lineBreak: <br /> }}
            />
          </H2>
          <p className={css.description}>
            <FormattedMessage id="SectionHeroClient.description" />
          </p>

          <div className={css.searchWrapper}>
            <input
              className={css.input}
              type='text'
              placeholder={intl.formatMessage({ id: "SectionHeroClient.inputPlaceholder" })}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <img
              className={css.searchIcon}
              src={searchIcon}
              onClick={handleSearch}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHeroClient;
